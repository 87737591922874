import pace from './modules/pace';
import vdot from './modules/vdot';
import hr from './modules/hr';
import plan from './modules/plan';
import tabs from './modules/tabs';

window.addEventListener('DOMContentLoaded', () => {
    pace();
    vdot();
    hr();
    plan();
    tabs();
});