const vdot = () => {
    const event = document.querySelector('#vdot-event'),
          eventValue = document.querySelector('.event-value'),
          resInfo = document.querySelector('.res-info'),
          eventHr = document.querySelector('#vdot-hr'),
          eventMin = document.querySelector('#vdot-min'),
          eventSec = document.querySelector('#vdot-sec'),
          pred = document.querySelector('.vdot-pred'),
          int = document.querySelector('.vdot-int'),
          train = document.querySelector('.vdot-train'),
          recovery = document.querySelector('#recovery'),
          easy = document.querySelector('#easy'),
          long = document.querySelector('#long'),
          tempo = document.querySelector('#tempo'),
          threshInt = document.querySelector('#threshInt'),
          threshold = document.querySelector('#threshold'),
          intervals = document.querySelector('#intervals'),
          repeats = document.querySelector('#repeats');


    let vdotData = {};

    try {
        fetch('./vdot.json')
            .then(response => {
                return response.json();
            })
            .then(data => {
                vdotData = data;
            })
            .catch(() => {
                console.log("Ошибка подключения к серверу!");
            });
    } catch (err) {
        console.log(err);
    }

    event.addEventListener('input', () => {
        if (event.value === "0") {
            eventValue.style.display = "none";
        } else {
            eventValue.style.display = "block";
            eventValue.textContent = `Расстояние - ${event.value} м`;
        }
    });

    document.querySelector('#vdot-calc').addEventListener('click', () => {
        let vdotRes = '',
            paceRes, paceResMin, paceResSec;
        const eventRes = +(eventHr.value * 3600) + +(eventMin.value * 60) + +eventSec.value,
              eventVal = event.value;

        paceRes = eventRes / eventVal;
        paceResMin = Math.floor(paceRes * 1000 / 60);
        paceResSec = Math.floor(paceRes * 1000 % 60);
        if (paceResSec.toString().length < 2) {
            paceResSec = `0${paceResSec}`;
        }

        resInfo.style.display = "none";
        if (event.value === "0") {
            eventValue.style.display = "block";
            eventValue.textContent = "Выберите дистанцию!";
        } else if (eventRes === 0) {
            resInfo.style.display = "block";
            resInfo.textContent = "Укажите время на дистанции!";
        } else {
            resInfo.style.display = "block";
            resInfo.textContent = `Темп - ${paceResMin}:${paceResSec} мин/км`;
            for (let i = 30; i <= 85; i++) {
                if (eventRes < vdotData[eventVal][85]) {
                    resInfo.style.display = "block";
                    resInfo.textContent = "Так быстро не бегают даже кенийцы:)"
                    break;
                } else if (vdotData[eventVal][i] <= eventRes) {
                    vdotRes = i;
                    let vdot1500min = Math.floor(vdotData[1500][i]/60),
                        vdot1500sec = Math.floor(vdotData[1500][i]%60),
                        vdotMileMin = Math.floor(vdotData[1609][i]/60),
                        vdotMileSec = Math.floor(vdotData[1609][i]%60),
                        vdot3000min = Math.floor(vdotData[3000][i]/60),
                        vdot3000sec = Math.floor(vdotData[3000][i]%60),
                        vdot2MileMin = Math.floor(vdotData[3219][i]/60),
                        vdot2MileSec = Math.floor(vdotData[3219][i]%60),
                        vdot5000Min = Math.floor(vdotData[5000][i]/60),
                        vdot5000Sec = Math.floor(vdotData[5000][i]%60),
                        vdot10000Hr = Math.floor(vdotData[10000][i]/3600),
                        vdot10000Min = Math.floor(vdotData[10000][i] % 3600 / 60),
                        vdot10000Sec = Math.floor(vdotData[10000][i]%60),
                        vdot15000Hr = Math.floor(vdotData[15000][i]/3600),
                        vdot15000Min = Math.floor(vdotData[15000][i] % 3600 / 60),
                        vdot15000Sec = Math.floor(vdotData[15000][i]%60),
                        vdot21098Hr = Math.floor(vdotData[21098][i]/3600),
                        vdot21098Min = Math.floor(vdotData[21098][i] % 3600 / 60),
                        vdot21098Sec = Math.floor(vdotData[21098][i]%60),
                        vdot42195Hr = Math.floor(vdotData[42195][i]/3600),
                        vdot42195Min = Math.floor(vdotData[42195][i] % 3600 / 60),
                        vdot42195Sec = Math.floor(vdotData[42195][i]%60);

                    let easyMinLow = Math.floor((vdotData['easy'][i]+20)/60),
                        easySecLow = Math.floor((vdotData['easy'][i]+20)%60),
                        easyMin= Math.floor(vdotData['easy'][i]/60),
                        easySec = Math.floor(vdotData['easy'][i]%60),
                        marathMinLow = Math.floor((vdotData['marathon'][i]+20)/60),
                        marathSecLow = Math.floor((vdotData['marathon'][i]+20)%60),
                        marathMin = Math.floor(vdotData['marathon'][i]/60),
                        marathSec = Math.floor(vdotData['marathon'][i]%60),
                        threshMinLow = Math.floor((vdotData['threshold'][i]+20)/60),
                        threshSecLow = Math.floor((vdotData['threshold'][i]+20)%60),
                        threshMin = Math.floor(vdotData['threshold'][i]/60),
                        threshSec = Math.floor(vdotData['threshold'][i]%60),
                        interMinLow = Math.floor((vdotData['interval'][i]+20)/60),
                        interSecLow = Math.floor((vdotData['interval'][i]+20)%60),
                        interMin = Math.floor(vdotData['interval'][i]/60),
                        interSec = Math.floor(vdotData['interval'][i]%60),
                        repMinLow = Math.floor(((vdotData['repeats'][i]*5)+20)/60),
                        repSecLow = Math.floor(((vdotData['repeats'][i]*5)+20)%60),
                        repMin = Math.floor(vdotData['repeats'][i]*5/60),
                        repSec = Math.floor((vdotData['repeats'][i]*5)%60);

                    let values = [easySec, easySecLow, marathSec, marathSecLow, threshSec, threshSecLow, interSec, interSecLow, repSec, repSecLow, vdot1500sec, vdotMileSec, vdot3000sec, vdot2MileSec, vdot15000Hr, vdot5000Sec, vdot10000Hr, vdot10000Min, vdot10000Sec, vdot15000Hr, vdot15000Min, vdot15000Sec, vdot21098Hr, vdot21098Min, vdot21098Sec, vdot42195Hr, vdot42195Min, vdot42195Sec];

                    let addZero = values.map(item => {
                        if (item.toString().length < 2) {
                            item = `0${item}`;
                        }
                        return item.toString();
                    });

                    [easySec, easySecLow, marathSec, marathSecLow, threshSec, threshSecLow, interSec, interSecLow, repSec, repSecLow, vdot1500sec, vdotMileSec, vdot3000sec, vdot2MileSec, vdot15000Hr, vdot5000Sec, vdot10000Hr, vdot10000Min, vdot10000Sec, vdot15000Hr, vdot15000Min, vdot15000Sec, vdot21098Hr, vdot21098Min, vdot21098Sec, vdot42195Hr, vdot42195Min, vdot42195Sec] = addZero;

                    pred.style.display = "grid";
                    pred.innerHTML = `
                        <h3>Ваш уровень VDOT: ${vdotRes}</h3>
                        <span>1500 метров - <b>${vdot1500min}:${vdot1500sec}</b></span>
                        <span>1 миля - <b>${vdotMileMin}:${vdotMileSec}</b></span>
                        <span>3000 метров - <b>${vdot3000min}:${vdot3000sec}</b></span>
                        <span>2 мили - <b>${vdot2MileMin}:${vdot2MileSec}</b></span>
                        <span>5000 метров - <b>${vdot5000Min}:${vdot5000Sec}</b></span>
                        <span>10000 метров - <b>${vdot10000Hr}:${vdot10000Min}:${vdot10000Sec}</b></span>
                        <span>15000 метров - <b>${vdot15000Hr}:${vdot15000Min}:${vdot15000Sec}</b></span>
                        <span>Полумарафон - <b>${vdot21098Hr}:${vdot21098Min}:${vdot21098Sec}</b></span>
                        <span>Марафон - <b>${vdot42195Hr}:${vdot42195Min}:${vdot42195Sec}</b></span>
                    `;

                    int.style.display = "grid";
                    int.innerHTML = `
                        <h3>Интенсивность тренировок при уровне VDOT: ${vdotRes}</h3>
                        <span>Темп восстановительного бега (1 зона) - <b>${easyMinLow}:${easySecLow}+</b> </span>
                        <span>Темп легкого бега (2 зона) - <b>${marathMinLow}:${marathSecLow} - ${easyMinLow}:${easySecLow}</b> </span>
                        <span>Темп марафонского бега (3 зона) - <b>${marathMin}:${marathSec} - ${marathMinLow}:${marathSecLow}</b> </span>
                        <span>Темп ПАНО (4 зона) - <b>${threshMin}:${threshSec} - ${threshMinLow}:${threshSecLow}</b> </span>
                        <span>Темп МПК интервалов (5 зона) - <b>${interMin}:${interSec} - ${interMinLow}:${interSecLow}</b> </span>
                        <span>Темп скоростных интервалов - <b>${repMinLow}:${repSecLow} - ${repMin}:${repSec}</b> </span>
                    `;

                    train.style.display = "grid";
                    recovery.innerHTML = `<b>Темп:</b> ${easyMinLow}:${easySecLow}+`;
                    easy.innerHTML = `<b>Темп:</b> ${marathMinLow}:${marathSecLow} - ${easyMinLow}:${easySecLow}`;
                    long.innerHTML = `<b>Темп:</b> ${marathMinLow}:${marathSecLow} - ${easyMinLow}:${easySecLow}`;
                    tempo.innerHTML = `<b>Темп:</b> ${marathMin}:${marathSec} - ${marathMinLow}:${marathSecLow}`;
                    threshInt.innerHTML = `<b>Темп:</b> ${threshMin}:${threshSec} - ${threshMinLow}:${threshSecLow}`;
                    threshold.innerHTML = `<b>Темп:</b> ${threshMin}:${threshSec} - ${threshMinLow}:${threshSecLow}`;
                    intervals.innerHTML = `<b>Темп:</b> ${interMin}:${interSec} - ${interMinLow}:${interSecLow}`;
                    repeats.innerHTML = `<b>Темп:</b> ${repMinLow}:${repSecLow} - ${repMin}:${repSec}`;

                    break;
                }
            }
        }
    });

    document.querySelector('#vdot-reset').addEventListener('click', () => {
        eventValue.style.display = "none";
        resInfo.style.display = 'none';
        pred.style.display = 'none';
        int.style.display = 'none';
        train.style.display = "none";
        document.querySelector('.vdot-form').reset();
    });
}

export default vdot;