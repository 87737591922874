const tabs = () => {
    const paceCalc = document.querySelector('.pace-calc'),
        hrCalc = document.querySelector('.hr-calc'),
        vdotCalc = document.querySelector('.vdot-calc'),
        planCalc = document.querySelector('.plan-calc');

    const calculators = document.querySelectorAll('.wrapper'),
        tabs = document.querySelectorAll('.tab');

    function closeCalculators() {
        calculators.forEach((item) => {
            item.style.display = 'none';
        });
    }

    closeCalculators();

    planCalc.style.display = '';

    tabs.forEach((item) => {
        item.addEventListener('click', (e) => {
            tabs.forEach((tab) => {
                tab.classList.remove('tab-active');
                if (tab == e.target) {
                    tab.classList.add('tab-active');

                    if (tab.classList.contains('pace-tab')) {
                        closeCalculators();
                        paceCalc.style.display = '';
                    } else if (tab.classList.contains('hr-tab')) {
                        closeCalculators();
                        hrCalc.style.display = '';
                    } else if (tab.classList.contains('vdot-tab')) {
                        closeCalculators();
                        vdotCalc.style.display = '';
                    } else if (tab.classList.contains('plan-tab')) {
                        closeCalculators();
                        planCalc.style.display = '';
                    }
                }
            });
        });
    });
};

export default tabs;
