const plan = () => {
    const form = document.querySelector('.plan-form'),
        baseDist = document.querySelector('#base-dist'),
        maxDist = document.querySelector('#max-dist'),
        baseVdot = document.querySelector('#base-vdot'),
        planTerm = document.querySelector('#plan-term'),
        planEvent = document.querySelector('#plan-event'),
        threshHr = document.querySelector('#thresh-hr');

    baseDist.value = localStorage.getItem('baseDist');
    maxDist.value = localStorage.getItem('maxDist');
    planTerm.value = localStorage.getItem('planTerm');
    threshHr.value = localStorage.getItem('hr');
    baseVdot.value = localStorage.getItem('vdot');

    let vdotData = {};
    let weekDist = 0;
    let vdot;
    let t = 1;

    try {
        fetch('./vdot.json')
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                vdotData = data;
            })
            .catch(() => {
                console.log('Ошибка подключения к серверу!');
            });
    } catch (err) {
        console.log(err);
    }

    function addInputInfo(message, input) {
        const distInfo = document.createElement('span');
        distInfo.classList.add('plan-input-info');
        distInfo.textContent = message;
        const inputInfo = document.querySelector('.plan-input-info');
        if (!inputInfo) {
            input.after(distInfo);
        }
        return;
    }

    function removeInputInfo(input) {
        const inputInfo = input.nextSibling;
        if (inputInfo) {
            inputInfo.remove();
        }
        return;
    }

    function paceCalc(vdot) {
        const ePace = vdotData['easy'][vdot],
            mPace = vdotData['marathon'][vdot],
            tPace = vdotData['threshold'][vdot],
            iPace = vdotData['interval'][vdot],
            rPace = vdotData['repeats'][vdot] * 5;

        return { ePace, mPace, tPace, iPace, rPace };
    }

    function hrCalc(zone) {
        const hrThresh = document.querySelector('#thresh-hr').value;
        const zone1 = Math.round(hrThresh * 0.66),
            zone2 = Math.round(hrThresh * 0.85),
            zone3 = Math.round(hrThresh * 0.905),
            zone4 = Math.round(hrThresh * 0.955),
            zone5 = Math.round(hrThresh * 1.015),
            zoneMax = Math.round(hrThresh * 1.055);

        switch (zone) {
            case 'recovery':
                return `${zone1} - ${zone2} уд/мин`;
            case 'easy':
                return `${zone2} - ${zone3} уд/мин`;
            case 'long':
                return `${zone2} - ${zone3} уд/мин`;
            case 'marathon':
                return `${zone3} - ${zone4} уд/мин`;
            case 'threshold':
                return `${zone4} - ${zone5} уд/мин`;
            case 'intervals':
                return `${zone5} - ${zoneMax} уд/мин`;
            case 'repeat':
                return `Пульс не измеряется`;
            case 'race':
                return `Индивидуально`;
        }
    }

    function phaseCalc(value) {
        let basePhase = 0,
            techPhase = 0,
            powerPhase = 0,
            perfPhase = 0;

        if (value <= 3) {
            basePhase = value;
        } else if (value <= 6) {
            basePhase = 3;
            perfPhase = value - 3;
        } else if (value <= 9) {
            basePhase = 3;
            powerPhase = value - 6;
            perfPhase = 3;
        } else if (value <= 12) {
            basePhase = 3;
            techPhase = value - 9;
            powerPhase = 3;
            perfPhase = 3;
        } else if (value <= 16) {
            basePhase = 4;
            techPhase = 3;
            powerPhase = value - 10;
            perfPhase = 3;
        } else if (value <= 20) {
            basePhase = 4;
            techPhase = value - 14;
            powerPhase = 6;
            perfPhase = 4;
        } else if (value <= 22) {
            basePhase = 5;
            techPhase = 6;
            powerPhase = 6;
            perfPhase = value - 17;
        } else if (value <= 24) {
            basePhase = 6;
            techPhase = 6;
            powerPhase = 6;
            perfPhase = value - 18;
        } else {
            basePhase = value - 18;
            techPhase = 6;
            powerPhase = 6;
            perfPhase = 6;
        }

        return { basePhase, techPhase, powerPhase, perfPhase };
    }

    function weekDistCalc(start, end, phase = '') {
        if (weekDist === 0) {
            weekDist = Math.round(baseDist.value * 1.15);
        }

        let weeksDistData = {};

        const maxDist = document.querySelector('#max-dist').value;

        for (let i = start; i <= end; i++) {
            if (phase !== 'perf') {
                if (i % 3 == 0) {
                    //Recovery week
                    weeksDistData[i] = Math.round(weekDist * 0.87);
                } else if (i % 3 == 1 && i !== 1) {
                    //Upper week
                    if (Math.round(weekDist * 1.15) - weekDist < 15) {
                        weekDist = Math.round(weekDist * 1.15);
                    } else {
                        weekDist += 15;
                    }
                    if (weekDist > maxDist) {
                        weekDist = maxDist;
                    }
                    weeksDistData[i] = weekDist;
                } else {
                    //Work week
                    weeksDistData[i] = weekDist;
                }
            } else {
                //Performance
                weeksDistData[i] = weekDist;
                if (end - i <= 4 && phase == 'perf') {
                    weeksDistData[i] = Math.round(weekDist * 0.9);
                }
                if (end - i === 3 && phase == 'perf') {
                    weeksDistData[i] = Math.round(weekDist * 0.8);
                }
                if (end - i === 2 && phase == 'perf') {
                    weeksDistData[i] = Math.round(weekDist * 0.7);
                }
                if (end - i === 1 && phase == 'perf') {
                    weeksDistData[i] = Math.round(weekDist * 0.6);
                }
                if (end === i && phase == 'perf') {
                    weeksDistData[i] = 'Подводка к соревнованию';
                }
            }
        }
        return weeksDistData;
    }

    function intervalsGenerator(dist, pace, min) {
        let intervalsDist = Math.min(dist * 0.08, 10);
        let intervalDist = Math.floor(((min * 60) / pace['iPace']) * 10) / 10;
        let intervalsRep = Math.floor(intervalsDist / intervalDist);
        return [intervalsRep, intervalDist];
    }

    function threshIntGen(dist, pace, min) {
        let intervalsDist = Math.min(
            Math.round(dist * 0.1),
            Math.round(2400 / pace['tPace'])
        );
        let intervalDist = Math.floor(((min * 60) / pace['tPace']) * 10) / 10;
        let intervalsRep = Math.round(intervalsDist / intervalDist);
        return [intervalsRep, intervalDist];
    }

    function renderPlan(pace) {
        const fragment = document.createDocumentFragment();

        const weeks = phaseCalc(planTerm.value);

        const phase1 = baseWeeks(weeks['basePhase']);
        fragment.append(phase1);

        if (planTerm.value >= 10) {
            const phase2 = techWeeks(
                weeks['basePhase'] + 1,
                weeks['basePhase'] + weeks['techPhase']
            );
            fragment.append(phase2);
        }

        if (planTerm.value >= 7) {
            const phase3 = powerWeeks(
                weeks['basePhase'] + weeks['techPhase'] + 1,
                weeks['basePhase'] + weeks['techPhase'] + weeks['powerPhase']
            );
            fragment.append(phase3);
        }

        if (planTerm.value >= 3) {
            const phase4 = perfWeeks(
                weeks['basePhase'] +
                    weeks['techPhase'] +
                    weeks['powerPhase'] +
                    1,
                weeks['basePhase'] +
                    weeks['techPhase'] +
                    weeks['powerPhase'] +
                    weeks['perfPhase']
            );
            fragment.append(phase4);
        }

        document.querySelector('.plan').append(fragment);
    }

    function baseWeeks(end) {
        const phaseTpl = document.createElement('div');
        phaseTpl.classList.add('phase__wrapper');
        phaseTpl.style.borderColor = 'green';
        phaseTpl.insertAdjacentHTML(
            'afterbegin',
            `<h3 class="week__header">Развитие выносливости</h3>`
        );

        const weeksList = weekDistCalc(1, end);

        vdot = baseVdot.value;

        for (let week in weeksList) {
            if (week % 4 === 1 && week != 1 && vdot < 85) {
                vdot++;
            }

            let pace = paceCalc(vdot);

            const weekTpl = weekTemplate(
                'base',
                weeksList[week],
                week,
                vdot,
                pace
            );

            phaseTpl.append(weekTpl);
        }

        return phaseTpl;
    }

    function techWeeks(start, end) {
        const phaseTpl = document.createElement('div');
        phaseTpl.classList.add('phase__wrapper');
        phaseTpl.style.borderColor = 'red';
        phaseTpl.insertAdjacentHTML(
            'afterbegin',
            `<h3 class="week__header">Развитие техники</h3>`
        );

        const weeksList = weekDistCalc(start, end);

        for (let week in weeksList) {
            if (week % 4 === 1 && week != 1 && vdot < 85) {
                vdot++;
            }

            let pace = paceCalc(vdot);

            const weekTpl = weekTemplate(
                'tech',
                weeksList[week],
                week,
                vdot,
                pace
            );

            phaseTpl.append(weekTpl);
        }

        return phaseTpl;
    }

    function powerWeeks(start, end) {
        const phaseTpl = document.createElement('div');
        phaseTpl.classList.add('phase__wrapper');
        phaseTpl.style.borderColor = 'orange';
        phaseTpl.insertAdjacentHTML(
            'afterbegin',
            `<h3 class="week__header">Развитие мощности</h3>`
        );

        const weeksList = weekDistCalc(start, end);

        for (let week in weeksList) {
            if (week % 4 === 1 && week != 1 && vdot < 85) {
                vdot++;
            }

            let pace = paceCalc(vdot);

            const weekTpl = weekTemplate(
                'power',
                weeksList[week],
                week,
                vdot,
                pace
            );

            phaseTpl.append(weekTpl);
        }

        return phaseTpl;
    }

    function perfWeeks(start, end) {
        const phaseTpl = document.createElement('div');
        phaseTpl.classList.add('phase__wrapper');
        phaseTpl.style.borderColor = 'blue';
        phaseTpl.insertAdjacentHTML(
            'afterbegin',
            `<h3 class="week__header">Развитие производительности</h3>`
        );

        const weeksList = weekDistCalc(start, end, 'perf');

        for (let week in weeksList) {
            if (week % 4 === 1 && week != 1 && vdot < 85) {
                vdot++;
            }

            let pace = paceCalc(vdot);

            const weekTpl = weekTemplate(
                'perf',
                weeksList[week],
                week,
                vdot,
                pace
            );

            phaseTpl.append(weekTpl);
        }

        return phaseTpl;
    }

    function weekTemplate(phase, dist, week, vdot, pace) {
        const weekWrap = document.createElement('div');
        weekWrap.classList.add('week__wrapper');
        let totalWeekDist = dist;

        if (phase === 'base') {
            let longDist = Math.min(
                Math.round(dist * 0.25),
                Math.round(9000 / pace['ePace'])
            );
            if (planEvent.value <= 15000) {
                longDist = Math.min(longDist, Math.round(5400 / pace['ePace']));
            }
            const easyDist = Math.min(
                Math.round((dist - longDist) / 5),
                Math.round(7200 / pace['ePace'])
            );
            totalWeekDist = longDist + easyDist * 5;

            if (week >= 4) {
                for (let i = 1; i <= 7; i++) {
                    let day;
                    switch (i) {
                        case 1:
                            day = dayTemplate();
                            break;
                        case 2:
                        case 4:
                            day = dayTemplate(
                                i,
                                easyDist,
                                'easy',
                                pace['ePace'],
                                [8, 20]
                            );
                            break;
                        case 3:
                        case 5:
                        case 6:
                            day = dayTemplate(
                                i,
                                easyDist,
                                'easy',
                                pace['ePace']
                            );
                            break;
                        case 7:
                            day = dayTemplate(
                                i,
                                longDist,
                                'long',
                                pace['ePace']
                            );
                            break;
                    }
                    weekWrap.append(day);
                }
            } else {
                for (let i = 1; i <= 7; i++) {
                    let day;
                    switch (i) {
                        case 1:
                            day = dayTemplate();
                            break;
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                            day = dayTemplate(
                                i,
                                easyDist,
                                'easy',
                                pace['ePace']
                            );
                            break;
                        case 7:
                            day = dayTemplate(
                                i,
                                longDist,
                                'long',
                                pace['ePace']
                            );
                            break;
                    }
                    weekWrap.append(day);
                }
            }
        } else if (phase === 'tech') {
            //T1
            let repeatsDist = Math.min(dist * 0.05, 8);
            const repeats200 = Math.floor(repeatsDist / 0.2);
            const repeats400 = Math.floor(repeatsDist / 0.4);

            let trainingType1;
            if (week % 2 != 0) {
                trainingType1 = [repeats200, 200];
            } else {
                trainingType1 = [repeats400, 400];
            }

            //T2
            let threshDist = Math.min(
                Math.round(dist * 0.1),
                Math.round(2400 / pace['tPace'])
            );
            let threshIntDist, threshIntNumb, trainingType2;
            if (trainingType1[1] == 200) {
                threshIntDist = Math.floor((600 / pace['tPace']) * 10) / 10;
                threshIntNumb = Math.round(threshDist / threshIntDist);
                trainingType2 = [threshIntNumb, threshIntDist, 2];
            } else {
                threshIntDist = Math.floor((300 / pace['tPace']) * 10) / 10;
                threshIntNumb = Math.round(threshDist / threshIntDist);
                trainingType2 = [threshIntNumb, threshIntDist, 1];
            }
            threshDist = Math.round(threshIntDist * threshIntNumb);
            if (planEvent.value <= 15000) {
                if (week % 2 == 0) {
                    threshIntDist = 2700 / pace['mPace'];
                    trainingType2 = [0, threshIntDist, 0];
                }
            }

            //T3
            let intDist = 0;
            let trainingType3;
            if (planEvent.value <= 15000) {
                if (week % 2 == 0) {
                    trainingType3 = intervalsGenerator(dist, pace, 3);
                    trainingType3.push(3);
                } else {
                    trainingType3 = intervalsGenerator(dist, pace, 4);
                    trainingType3.push(4);
                }
                intDist = trainingType3[0] * trainingType3[1];
            }

            let longDist = Math.min(
                Math.round(dist * 0.25),
                Math.round(9000 / pace['ePace'])
            );
            if (planEvent.value <= 15000) {
                longDist = Math.min(longDist, Math.round(5400 / pace['ePace']));
            }

            let easyDist = Math.min(
                Math.round(
                    (dist -
                        (repeatsDist * 2 + 3) -
                        longDist -
                        (threshDist + 3)) /
                        3
                ),
                Math.round(7200 / pace['ePace'])
            );
            if (planEvent.value <= 15000) {
                easyDist = Math.min(
                    Math.round(
                        (dist - repeatsDist - longDist - threshDist - intDist) /
                            2
                    ),
                    Math.round(7200 / pace['ePace'])
                );
            }

            if (planEvent.value < 15000) {
                for (let i = 1; i <= 7; i++) {
                    let day;
                    switch (i) {
                        case 1:
                            day = dayTemplate();
                            break;
                        case 2:
                            day = dayTemplate(
                                i,
                                repeatsDist,
                                'repeat',
                                pace['rPace'],
                                trainingType1
                            );
                            break;
                        case 4:
                            if (week % 2 == 0) {
                                day = dayTemplate(
                                    i,
                                    threshIntDist,
                                    'marathon',
                                    pace['mPace'],
                                    trainingType2
                                );
                                break;
                            } else {
                                day = dayTemplate(
                                    i,
                                    threshIntDist,
                                    'threshold',
                                    pace['tPace'],
                                    trainingType2
                                );
                                break;
                            }

                        case 3:
                        case 5:
                            day = dayTemplate(
                                i,
                                easyDist,
                                'easy',
                                pace['ePace']
                            );
                            break;
                        case 6:
                            day = dayTemplate(
                                i,
                                easyDist,
                                'intervals',
                                pace['iPace'],
                                trainingType3
                            );
                            break;
                        case 7:
                            day = dayTemplate(
                                i,
                                longDist,
                                'long',
                                pace['ePace']
                            );
                            break;
                    }
                    weekWrap.append(day);
                }
            } else {
                for (let i = 1; i <= 7; i++) {
                    let day;
                    switch (i) {
                        case 1:
                            day = dayTemplate();
                            break;
                        case 2:
                            day = dayTemplate(
                                i,
                                repeatsDist,
                                'repeat',
                                pace['rPace'],
                                trainingType1
                            );
                            break;
                        case 4:
                            day = dayTemplate(
                                i,
                                threshIntDist,
                                'threshold',
                                pace['tPace'],
                                trainingType2
                            );
                            break;
                        case 3:
                        case 5:
                        case 6:
                            day = dayTemplate(
                                i,
                                easyDist,
                                'easy',
                                pace['ePace']
                            );
                            break;
                        case 7:
                            day = dayTemplate(
                                i,
                                longDist,
                                'long',
                                pace['ePace']
                            );
                            break;
                    }
                    totalWeekDist =
                        longDist +
                        easyDist * 3 +
                        (threshDist + 3) +
                        (Math.round(repeatsDist) * 2 + 3);
                    weekWrap.append(day);
                }
            }
        } else if (phase === 'power') {
            //T1
            let trainingType1;
            if (t % 3 == 0) {
                trainingType1 = intervalsGenerator(dist, pace, 5);
                trainingType1.push(5);
                t++;
            } else if (t % 2 == 0) {
                trainingType1 = intervalsGenerator(dist, pace, 4);
                trainingType1.push(4);
                t++;
            } else {
                trainingType1 = intervalsGenerator(dist, pace, 3);
                trainingType1.push(3);
                t++;
            }
            let intDist = trainingType1[0] * trainingType1[1];

            //T2
            let trainingType2;
            if ((t - 1) % 3 == 0) {
                let thresh = Math.round(1200 / pace['tPace']);
                trainingType2 = [1, thresh, 0];
            } else if ((t - 1) % 2 == 0) {
                trainingType2 = threshIntGen(dist, pace, 5);
                trainingType2.push(1);
            } else {
                trainingType2 = threshIntGen(dist, pace, 10);
                trainingType2.push(2);
            }
            let threshDist = trainingType2[0] * trainingType2[1];

            if (planEvent.value < 15000) {
                if (week % 2 == 0) {
                    threshDist = Math.round((1800 / pace['tPace']) * 1.023);
                    trainingType2 = [1, threshDist, 0];
                } else {
                    threshDist = Math.round(1200 / pace['tPace']);
                    trainingType2 = [1, threshDist, 0];
                }
            }

            //T3
            let longDist = Math.min(
                Math.round(dist * 0.25),
                Math.round(9000 / pace['ePace'])
            );
            let marathDist = Math.min(
                Math.round(dist * 0.2),
                Math.round(9000 / pace['mPace']),
                25
            );

            let repeatsDist = Math.min(dist * 0.05, 8);
            const repeats200 = Math.floor(repeatsDist / 0.2);

            if (planEvent.value < 15000) {
                marathDist = Math.round(3600 / pace['mPace']);
            }

            let t3Dist = longDist;
            if (t % 3 === 0) {
                t3Dist = marathDist;
            }

            let trainingType3;
            if (planEvent.value < 15000) {
                if (week % 2 == 0) {
                    repeatsDist = Math.round(repeats200 * 0.2);
                    trainingType3 = [repeats200, 200];
                    t3Dist = repeatsDist;
                } else {
                    t3Dist = marathDist;
                }
            }

            let easyDist = Math.round(
                (dist - intDist - t3Dist - threshDist) / 3
            );

            if (planEvent.value < 15000) {
                if (week % 2 == 0) {
                    easyDist = Math.round(
                        (dist - intDist - t3Dist - threshDist - longDist) / 2
                    );
                } else {
                    easyDist = Math.round(
                        (dist - intDist - t3Dist - threshDist) / 3
                    );
                }
            }

            easyDist = Math.min(easyDist, Math.round(7200 / pace['ePace']));

            if (planEvent.value == '42195' && week == planTerm.value - 5) {
                longDist = Math.min(35, Math.round(9000 / pace['ePace']));
                easyDist = Math.round(
                    (dist - intDist - threshDist - longDist) / 3
                );
                easyDist = Math.min(easyDist, Math.round(7200 / pace['ePace']));
                for (let i = 1; i <= 7; i++) {
                    let day;
                    switch (i) {
                        case 1:
                            day = dayTemplate();
                            break;
                        case 2:
                            day = dayTemplate(
                                i,
                                intDist,
                                'intervals',
                                pace['iPace'],
                                trainingType1
                            );
                            break;
                        case 4:
                            day = dayTemplate(
                                i,
                                threshDist,
                                'threshold',
                                pace['tPace'],
                                trainingType2
                            );
                            break;
                        case 3:
                        case 5:
                        case 6:
                            day = dayTemplate(
                                i,
                                easyDist,
                                'easy',
                                pace['ePace']
                            );
                            break;
                        case 7:
                            day = dayTemplate(
                                i,
                                longDist,
                                'long',
                                pace['ePace']
                            );
                            break;
                    }
                    weekWrap.append(day);
                }
            } else if (planEvent.value < 15000) {
                for (let i = 1; i <= 7; i++) {
                    let day;
                    switch (i) {
                        case 1:
                            day = dayTemplate();
                            break;
                        case 2:
                            day = dayTemplate(
                                i,
                                intDist,
                                'intervals',
                                pace['iPace'],
                                trainingType1
                            );
                            break;
                        case 4:
                            if (week % 2 == 0) {
                                day = dayTemplate(
                                    i,
                                    threshDist,
                                    'threshold',
                                    Math.round(pace['tPace'] * 1.023),
                                    trainingType2
                                );
                                break;
                            } else {
                                day = dayTemplate(
                                    i,
                                    threshDist,
                                    'threshold',
                                    pace['tPace'],
                                    trainingType2
                                );
                                break;
                            }

                        case 3:
                        case 5:
                            day = dayTemplate(
                                i,
                                easyDist,
                                'easy',
                                pace['ePace']
                            );
                            break;
                        case 6:
                            if (week % 2 == 0) {
                                day = dayTemplate(
                                    i,
                                    repeatsDist,
                                    'repeat',
                                    pace['rPace'],
                                    trainingType3
                                );
                                break;
                            } else {
                                day = dayTemplate(
                                    i,
                                    easyDist,
                                    'easy',
                                    pace['ePace']
                                );
                                break;
                            }
                        case 7:
                            if (week % 2 == 0) {
                                day = dayTemplate(
                                    i,
                                    longDist,
                                    'long',
                                    pace['ePace']
                                );
                                break;
                            } else {
                                day = dayTemplate(
                                    i,
                                    marathDist,
                                    'marathon',
                                    pace['mPace']
                                );
                                break;
                            }
                    }
                    weekWrap.append(day);
                }
            } else {
                for (let i = 1; i <= 7; i++) {
                    let day;
                    switch (i) {
                        case 1:
                            day = dayTemplate();
                            break;
                        case 2:
                            day = dayTemplate(
                                i,
                                intDist,
                                'intervals',
                                pace['iPace'],
                                trainingType1
                            );
                            break;
                        case 4:
                            day = dayTemplate(
                                i,
                                threshDist,
                                'threshold',
                                pace['tPace'],
                                trainingType2
                            );
                            break;
                        case 3:
                        case 5:
                        case 6:
                            day = dayTemplate(
                                i,
                                easyDist,
                                'easy',
                                pace['ePace']
                            );
                            break;
                        case 7:
                            if (t % 3 === 0) {
                                day = dayTemplate(
                                    i,
                                    t3Dist,
                                    'marathon',
                                    pace['mPace']
                                );
                                break;
                            } else {
                                day = dayTemplate(
                                    i,
                                    t3Dist,
                                    'long',
                                    pace['ePace']
                                );
                                break;
                            }
                    }
                    weekWrap.append(day);
                }
            }
        } else if (phase === 'perf') {
            //T1
            let threshDist;
            let t1Pace = pace['tPace'];

            if (planEvent.value > 15000) {
                if (t % 3 == 0) {
                    t1Pace = Math.round(t1Pace * 1.034);
                    threshDist = Math.round(2400 / t1Pace);
                    t++;
                } else if (t % 2 == 0) {
                    t1Pace = Math.round(t1Pace * 1.023);
                    threshDist = Math.round(1800 / t1Pace);
                    t++;
                } else {
                    threshDist = Math.round(1200 / t1Pace);
                    t++;
                }
            } else {
                if (week % 2 == 0) {
                    t1Pace = Math.round(t1Pace * 1.034);
                    threshDist = Math.round(2400 / t1Pace);
                } else {
                    t1Pace = Math.round(t1Pace * 1.023);
                    threshDist = Math.round(1800 / t1Pace);
                }
            }

            //T2
            let marathDist = 3600 / pace['mPace'];

            let trainingType2;
            if (week % 2 == 0) {
                trainingType2 = intervalsGenerator(dist, pace, 5);
                trainingType2.push(5);
            } else {
                trainingType2 = intervalsGenerator(dist, pace, 4);
                trainingType2.push(4);
            }
            let intDist = trainingType2[0] * trainingType2[1];

            let t2Dist;
            if (planEvent.value <= 15000) {
                t2Dist = intDist;
            } else {
                t2Dist = marathDist;
            }

            //T3
            let longDist = Math.min(
                Math.round(dist * 0.25),
                Math.round(9000 / pace['ePace'])
            );

            let fartlekDist;
            let threshFartlek, intFartlek, repFartlek;

            threshFartlek = Math.floor((300 / pace['tPace']) * 10) / 10;
            intFartlek = Math.floor((180 / pace['iPace']) * 10) / 10;
            repFartlek = 0.2;

            fartlekDist = 3 * (threshFartlek + intFartlek + repFartlek);

            let tPaceMin = Math.floor(pace['tPace'] / 60);
            let iPaceMin = Math.floor(pace['iPace'] / 60);
            let rPaceMin = Math.floor(pace['rPace'] / 60);
            let tPaceSec = Math.floor(pace['tPace'] % 60);
            let iPaceSec = Math.floor(pace['iPace'] % 60);
            let rPaceSec = Math.floor(pace['rPace'] % 60);

            let paces = [tPaceSec, iPaceSec, rPaceSec].map((item) => {
                if (item < 10) {
                    item = `0${item}`;
                }
                return item;
            });

            [tPaceSec, iPaceSec, rPaceSec] = paces;

            let fartlek = [
                threshFartlek * 1000,
                `${tPaceMin}:${tPaceSec}`,
                1,
                intFartlek * 1000,
                `${iPaceMin}:${iPaceSec}`,
                3,
                repFartlek * 1000,
                `${rPaceMin}:${rPaceSec}`,
                2,
            ];

            if (planEvent.value <= 15000) {
                longDist = Math.min(
                    Math.round(dist * 0.25),
                    Math.round(7200 / pace['ePace'])
                );
            }

            //Easy days
            let easyDist = Math.round(
                (dist - threshDist - t2Dist - longDist) / 3
            );
            if (planEvent.value <= 15000) {
                easyDist = Math.round(
                    (dist - threshDist - t2Dist - fartlekDist - longDist) / 2
                );
            }
            easyDist = Math.min(easyDist, Math.round(7200 / pace['ePace']));

            const easyDist30 = Math.round(1800 / pace['ePace']);
            const easyDist45 = Math.round(2700 / pace['ePace']);

            //Race week
            const raceDist = planEvent.value;
            let raceRes = vdotData[raceDist][vdot];
            let racePace = (raceRes * 1000) / raceDist;

            let racePaceMin = Math.floor((racePace % 3600) / 60);
            let racePaceSec = Math.floor(racePace % 60);
            if (racePaceMin < 10) {
                racePaceMin = `0${racePaceMin}`;
            }
            if (racePaceSec < 10) {
                racePaceSec = `0${racePaceMin}`;
            }

            racePace = `${racePaceMin}:${racePaceSec} мин/км`;

            let raceResHr = Math.floor(raceRes / 3600);
            let raceResMin = Math.floor((raceRes % 3600) / 60);
            let raceResSec = Math.floor(raceRes % 60);
            if (raceResMin < 10) {
                raceResMin = `0${raceResMin}`;
            }
            if (raceResSec < 10) {
                raceResSec = `0${raceResSec}`;
            }

            if (raceResHr == 0) {
                raceRes = `${raceResMin}:${raceResSec}`;
            } else {
                raceRes = `${raceResHr}:${raceResMin}:${raceResSec}`;
            }

            const easyTerm = easyDist * pace['ePace'];

            if (planEvent.value <= 15000 && week != planTerm.value) {
                for (let i = 1; i <= 7; i++) {
                    let day;
                    switch (i) {
                        case 1:
                            day = dayTemplate();
                            break;
                        case 2:
                            day = dayTemplate(
                                i,
                                threshDist,
                                'threshold',
                                t1Pace
                            );
                            break;
                        case 4:
                            day = dayTemplate(
                                i,
                                intDist,
                                'intervals',
                                pace['iPace'],
                                trainingType2
                            );
                            break;
                        case 3:
                        case 5:
                            day = dayTemplate(
                                i,
                                easyDist,
                                'easy',
                                pace['ePace']
                            );
                            break;
                        case 6:
                            day = dayTemplate(
                                i,
                                fartlekDist,
                                'fartlek',
                                pace['ePace'],
                                fartlek
                            );
                            break;
                        case 7:
                            day = dayTemplate(
                                i,
                                longDist,
                                'long',
                                pace['ePace']
                            );
                            break;
                    }
                    weekWrap.append(day);
                }
            } else if (
                planEvent.value == '42195' &&
                week == planTerm.value - 3
            ) {
                longDist = Math.min(35, Math.round(9000 / pace['ePace']));
                easyDist = Math.round(
                    (dist - threshDist - marathDist - longDist) / 3
                );
                easyDist = Math.min(easyDist, Math.round(7200 / pace['ePace']));
                for (let i = 1; i <= 7; i++) {
                    let day;
                    switch (i) {
                        case 1:
                        case 6:
                            day = dayTemplate();
                            break;
                        case 2:
                            day = dayTemplate(
                                i,
                                threshDist,
                                'threshold',
                                t1Pace
                            );
                            break;
                        case 3:
                        case 4:
                        case 5:
                            day = dayTemplate(
                                i,
                                easyDist,
                                'easy',
                                pace['ePace']
                            );
                            break;
                        case 7:
                            day = dayTemplate(
                                i,
                                longDist,
                                'marathon',
                                pace['mPace']
                            );
                            break;
                    }
                    weekWrap.append(day);
                }
            } else if (
                planEvent.value == '42195' &&
                week == planTerm.value - 5
            ) {
                longDist = Math.min(35, Math.round(9000 / pace['ePace']));
                easyDist = Math.round(
                    (dist - threshDist - marathDist - longDist) / 3
                );
                easyDist = Math.min(easyDist, Math.round(7200 / pace['ePace']));
                for (let i = 1; i <= 7; i++) {
                    let day;
                    switch (i) {
                        case 1:
                        case 6:
                            day = dayTemplate(i);
                            break;
                        case 2:
                            day = dayTemplate(
                                i,
                                threshDist,
                                'threshold',
                                t1Pace
                            );
                            break;
                        case 3:
                        case 4:
                        case 5:
                            day = dayTemplate(
                                i,
                                easyDist,
                                'easy',
                                pace['ePace']
                            );
                            break;
                        case 7:
                            day = dayTemplate(
                                i,
                                longDist,
                                'long',
                                pace['ePace']
                            );
                            break;
                    }
                    weekWrap.append(day);
                }
            } else if (week === planTerm.value) {
                //Race week
                for (let i = 1; i <= 7; i++) {
                    let day;
                    switch (i) {
                        case 1:
                        case 5:
                            day = dayTemplate(i);
                            break;
                        case 2:
                            day = dayTemplate(
                                i,
                                threshDist,
                                'threshold',
                                pace['tPace'],
                                [4, 1.2, 2]
                            );
                            break;
                        case 3:
                            day = dayTemplate(
                                i,
                                easyDist45,
                                'easy',
                                pace['ePace']
                            );
                            break;
                        case 4:
                        case 6:
                            day = dayTemplate(
                                i,
                                easyDist30,
                                'easy',
                                pace['ePace']
                            );
                            break;
                        case 7:
                            day = dayTemplate(i, raceDist, 'race', racePace, [
                                raceRes,
                            ]);
                            break;
                    }
                    weekWrap.append(day);
                }
            } else if (easyTerm < 1600) {
                easyDist = Math.round(
                    (dist - threshDist - marathDist - longDist) / 2
                );
                easyDist = Math.min(easyDist, Math.round(7200 / pace['ePace']));
                for (let i = 1; i <= 7; i++) {
                    let day;
                    switch (i) {
                        case 1:
                        case 6:
                            day = dayTemplate(i);
                            break;
                        case 2:
                            day = dayTemplate(
                                i,
                                threshDist,
                                'threshold',
                                t1Pace
                            );
                            break;
                        case 4:
                            day = dayTemplate(
                                i,
                                marathDist,
                                'marathon',
                                pace['mPace']
                            );
                            break;
                        case 3:
                        case 5:
                            day = dayTemplate(
                                i,
                                easyDist,
                                'easy',
                                pace['ePace']
                            );
                            break;
                        case 7:
                            day = dayTemplate(
                                i,
                                longDist,
                                'long',
                                pace['ePace']
                            );
                            break;
                    }
                    weekWrap.append(day);
                }
            } else {
                for (let i = 1; i <= 7; i++) {
                    let day;
                    switch (i) {
                        case 1:
                            day = dayTemplate();
                            break;
                        case 2:
                            day = dayTemplate(
                                i,
                                threshDist,
                                'threshold',
                                t1Pace
                            );
                            break;
                        case 4:
                            day = dayTemplate(
                                i,
                                marathDist,
                                'marathon',
                                pace['mPace']
                            );
                            break;
                        case 3:
                        case 5:
                        case 6:
                            day = dayTemplate(
                                i,
                                easyDist,
                                'easy',
                                pace['ePace']
                            );
                            break;
                        case 7:
                            day = dayTemplate(
                                i,
                                longDist,
                                'long',
                                pace['ePace']
                            );
                            break;
                    }
                    weekWrap.append(day);
                }
            }
        }

        weekWrap.insertAdjacentHTML(
            'afterbegin',
            `<h3 class="week__header">Неделя ${week}<br>
            Дистанция: ${totalWeekDist}<br>
            VDOT: ${vdot}</h3>
            </div>`
        );

        return weekWrap;
    }

    function dayTemplate(day = 1, dist = 0, type = 'rest', pace, trType) {
        const dayCard = document.createElement('div');
        dayCard.classList.add('day');

        const title = document.createElement('h4');
        let dayName;
        switch (day) {
            case 1:
                dayName = 'Понедельник';
                break;
            case 2:
                dayName = 'Вторник';
                break;
            case 3:
                dayName = 'Среда';
                break;
            case 4:
                dayName = 'Четверг';
                break;
            case 5:
                dayName = 'Пятница';
                break;
            case 6:
                dayName = 'Суббота';
                break;
            case 7:
                dayName = 'Воскресенье';
                break;
        }
        title.textContent = dayName;
        dayCard.append(title);

        const trainingData = trainingTemplate(type, dist, pace, trType);
        dayCard.append(trainingData['trainingType']);

        dayCard.append(trainingData['trainingDescr']);

        return dayCard;
    }

    function trainingTemplate(type, dist, pace, trType = [0, 0, 0]) {
        let trainingPaceMin = Math.floor(pace / 60),
            trainingPaceSec = pace % 60;
        if (trainingPaceSec < 10) {
            trainingPaceSec = `0${trainingPaceSec}`;
        }

        const hr = hrCalc(type);

        const trainingPace = `${trainingPaceMin}:${trainingPaceSec} мин/км`;
        const trainingType = document.createElement('span');
        switch (type) {
            case 'rest':
                trainingType.textContent = 'Отдых';
                break;
            case 'easy':
                trainingType.textContent = 'Легкий бег';
                break;
            case 'long':
                trainingType.textContent = 'Длительный бег';
                break;
            case 'marathon':
                trainingType.textContent = 'Марафонский бег';
                break;
            case 'threshold':
                trainingType.textContent = 'Темповый бег';
                break;
            case 'intervals':
                trainingType.textContent = 'Интервальный бег';
                break;
            case 'repeat':
                trainingType.textContent = 'Повторы';
                break;
            case 'race':
                trainingType.textContent = 'Забег';
                break;
            case 'fartlek':
                trainingType.textContent = 'Фартлек';
                break;
        }

        const trainingDescr = document.createElement('span');
        if (dist !== 0) {
            if (dist * pace > 3600 && type === 'easy' && trType[0] != 0) {
                trainingDescr.innerHTML = `
                    <b>Дистанция:</b> 2 тренировки <b>${Math.round(
                        dist / 2
                    )} км</b> и <b>${
                    dist - Math.round(dist / 2)
                } км </b> (включить ${trType[0]} ускорений по ${
                    trType[1]
                } сек в конце одной из пробежек)<br>
                    <b>Tемп:</b> ${trainingPace}<br>
                    <b>Пульс:</b> ${hr}<br>
                    <b>Интенсивность:</b> Легкая`;
            } else if (dist * pace > 3600 && type === 'easy') {
                trainingDescr.innerHTML = `
                    <b>Дистанция:</b> 2 тренировки <b>${Math.round(
                        dist / 2
                    )} км</b> и <b>${dist - Math.round(dist / 2)} км</b><br>
                    <b>Tемп:</b> ${trainingPace}<br>
                    <b>Пульс:</b> ${hr}<br>
                    <b>Интенсивность:</b> Легкая`;
            } else if (type === 'easy' && trType[0] != 0) {
                trainingDescr.innerHTML = `
                    <b>Дистанция:</b> ${Math.round(dist)} км (включить ${
                    trType[0]
                } ускорений по ${trType[1]} сек в конце пробежки)<br>
                    <b>Tемп:</b> ${trainingPace}<br>
                    <b>Пульс:</b> ${hr}<br>
                    <b>Интенсивность:</b> Легкая`;
            } else if (type === 'easy' || type === 'long') {
                trainingDescr.innerHTML = `
                    <b>Дистанция:</b> ${Math.round(dist)} км<br>
                    <b>Tемп:</b> ${trainingPace}<br>
                    <b>Пульс:</b> ${hr}<br>
                    <b>Интенсивность:</b> Легкая`;
            } else if (type === 'marathon') {
                trainingDescr.innerHTML = `
                    <b>Дистанция:</b> ${Math.round(dist)} км<br>
                    <b>Tемп:</b> ${trainingPace}<br>
                    <b>Пульс:</b> ${hr}<br>
                    <b>Интенсивность:</b> Отчасти тяжелая`;
            } else if (type === 'repeat') {
                trainingDescr.innerHTML = `
                    <b>Дистанция:</b> ${trType[0]} x ${trType[1]} м / полное восстановление <br>
                    <b>Tемп:</b> ${trainingPace}<br>
                    <b>Пульс:</b> ${hr}<br>
                    <b>Интенсивность:</b> Субмаксимальная скорость`;
            } else if (type === 'threshold' && trType[2] == 0) {
                trainingDescr.innerHTML = `
                    <b>Дистанция:</b> ${dist} км<br>
                    <b>Tемп:</b> ${trainingPace}<br>
                    <b>Пульс:</b> ${hr}<br>
                    <b>Интенсивность:</b> Тяжелая`;
            } else if (type === 'threshold') {
                trainingDescr.innerHTML = `
                    <b>Дистанция:</b> ${trType[0]} x ${trType[1] * 1000} м / ${
                    trType[2]
                } мин легкого бега<br>
                    <b>Tемп:</b> ${trainingPace}<br>
                    <b>Пульс:</b> ${hr}<br>
                    <b>Интенсивность:</b> Тяжелая`;
            } else if (type === 'intervals') {
                trainingDescr.innerHTML = `
                    <b>Дистанция:</b> ${trType[0]} x ${trType[1] * 1000} м / ${
                    trType[2]
                } мин бега трусцой<br>
                    <b>Tемп:</b> ${trainingPace}<br>
                    <b>Пульс:</b> ${hr}<br>
                    <b>Интенсивность:</b> Субмаксимальная`;
            } else if (type === 'race') {
                trainingDescr.innerHTML = `
                    <b>Дистанция:</b> ${dist} м<br>
                    <b>Tемп:</b> ${pace}<br>
                    <b>Прогноз результата:</b> ${trType[0]}<br>
                    <b>Интенсивность:</b> С улыбкой на лице:)`;
            } else if (type === 'fartlek') {
                trainingDescr.innerHTML = `
                    <b>Дистанция и темп:</b> 3 раза по ((${trType[0]} м в темпе ${trType[1]} мин/км / ${trType[2]} мин легкий бег) + (${trType[3]} м в темпе ${trType[4]} мин/км / ${trType[5]} мин бег трусцой) + (${trType[6]} м в темпе ${trType[7]} мин/км / ${trType[8]} мин восстановление))<br>
                    <b>Пульс:</b> Переменный<br>
                    <b>Интенсивность:</b> Переменная`;
            }
        } else {
            trainingDescr.textContent = `Отдых и восстановительные процедуры`;
            trainingDescr.style.textAlign = 'center';
        }

        return { trainingType, trainingDescr };
    }

    baseDist.addEventListener('input', () => {
        if (baseDist.value >= 80) {
            // addInputInfo('Некоторые дни будут состоять из 2-х тренировок', baseDist);
        } else {
            removeInputInfo(baseDist);
        }
    });

    planTerm.addEventListener('input', () => {
        planEvent.querySelectorAll('option').forEach((event) => {
            event.style.display = '';
        });

        if (planTerm.value < 18) {
            document.querySelector('#dist-42').style.display = 'none';
        }

        if (planTerm.value < 12) {
            document.querySelector('#dist-21').style.display = 'none';
        }

        if (planTerm.value < 8) {
            document.querySelector('#dist-10').style.display = 'none';
        }
    });

    baseVdot.addEventListener('input', () => {
        if (baseVdot.value < 30 || baseVdot.value > 85) {
            addInputInfo(
                'Уровень Vdot должен находиться в диапазоне 30 - 85',
                baseVdot
            );
        } else {
            removeInputInfo(baseVdot);
        }
    });

    document.querySelector('#plan-calc').addEventListener('click', () => {
        form.querySelectorAll('input').forEach((input) => {
            removeInputInfo(input.id);
        });

        const phase = document.querySelectorAll('.phase__wrapper');
        if (phase) {
            phase.forEach((item) => {
                item.remove();
            });
        }

        weekDist = 0;

        if (!baseDist.value) {
            addInputInfo(
                'Укажите ваш средний пробег в неделю за последние 4 недели',
                baseDist
            );
            return;
        }

        if (!planTerm.value) {
            addInputInfo(
                'Укажите количество недель для подготовки плана',
                planTerm
            );
            return;
        }

        if (!baseVdot.value) {
            addInputInfo(
                'Укажите уровень вашей физической формы в Vdot',
                baseVdot
            );
            return;
        }

        if (!threshHr.value) {
            addInputInfo(
                'Укажите ваш пульс на уровне порога анаэробного обмена (ПАНО)',
                threshHr
            );
            return;
        }

        localStorage.setItem('baseDist', baseDist.value);
        localStorage.setItem('maxDist', maxDist.value);
        localStorage.setItem('planTerm', planTerm.value);
        localStorage.setItem('vdot', baseVdot.value);
        localStorage.setItem('hr', threshHr.value);

        renderPlan(paceCalc(baseVdot.value));
    });

    document.querySelector('#plan-reset').addEventListener('click', () => {
        form.reset();

        weekDist = 0;

        const phase = document.querySelectorAll('.phase__wrapper');
        if (phase) {
            phase.forEach((item) => {
                item.remove();
            });
        }

        form.querySelectorAll('input').forEach((input) => {
            removeInputInfo(input.id);
        });
    });
};

export default plan;
