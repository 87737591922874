const hr = () => {
    const hrThresh = document.querySelector('#hr-thresh');

    let zones = document.querySelector('.hr-zones');

    const info = document.createElement('div');
    info.classList.add('hr-info');

    document.querySelector('#hr-calc').addEventListener('click', () => {
        info.textContent = '';
        if (!hrThresh.value) {
            info.textContent = 'Для расчета необходимо указать ваш пульс ПАНО!';
            hrThresh.after(info);
        } else {
            let zone1 = Math.round(hrThresh.value * 0.66),
                zone2 = Math.round(hrThresh.value * 0.85),
                zone3 = Math.round(hrThresh.value * 0.905),
                zone4 = Math.round(hrThresh.value * 0.955),
                zone5 = Math.round(hrThresh.value * 1.015),
                zoneMax = Math.round(hrThresh.value * 1.055);
            zones.style.display = "block";
            zones.innerHTML = `
                <span><b>1 зона</b>: ${zone1} - ${zone2} уд/мин</span>
                <span><b>2 зона</b>: ${zone2 + 1} - ${zone3} уд/мин</span>
                <span><b>3 зона</b>: ${zone3 + 1} - ${zone4} уд/мин</span>
                <span><b>4 зона</b>: ${zone4 + 1} - ${zone5} уд/мин</span>
                <span><b>5 зона</b>: ${zone5 + 1} - ${zoneMax} уд/мин</span>
            `;
        }
    });

    document.querySelector('#hr-reset').addEventListener('click', () => {
        document.querySelector('.hr-form').reset();
        zones.style.display = "none";
        info.remove();
    });
};

export default hr;