const pace = () => {
    const paceMin = document.querySelector('#pace-min'),
          paceSec = document.querySelector('#pace-sec'),
          dist = document.querySelector('#dist'),
          event = document.querySelector('#event'),
          termHr = document.querySelector('#term-hr'),
          termMin = document.querySelector('#term-min'),
          termSec = document.querySelector('#term-sec'),
          calc = document.querySelector('#calc'),
          reset = document.querySelector('#reset');

    let msg = document.createElement('div');
    msg.classList.add('msg');

    const isPaceValued = () => {
        if (paceMin.value || paceSec.value) {
            return true;
        }
    }

    const isDistValued = () => {
        if (dist.value) {
            return true;
        }
    }

    const isTermValued = () => {
        if (termHr.value || termMin.value || termSec.value) {
            return true;
        }
    }

    const calcTerm = (pace, dist) => {
        const termValue = pace * dist / 1000;

        termHr.value = Math.floor(termValue / 3600);
        if (termHr.value.toString().length < 2) {
            termHr.value = `0${termHr.value}`;
        }

        termMin.value = Math.floor((termValue % 3600) / 60);
        if (termMin.value.toString().length < 2) {
            termMin.value = `0${termMin.value}`;
        }

        termSec.value = Math.floor(termValue % 60);
        if (termSec.value.toString().length < 2) {
            termSec.value = `0${termSec.value}`;
        }
    }

    const calcDist = (pace, term) => {
        const distValue = term / pace * 1000;
        dist.value = Math.floor(distValue);
    }

    const calcPace = (term, dist) => {
        const paceValue = term / dist;
        paceMin.value = Math.floor(paceValue * 1000 / 60);
        if (paceMin.value.toString().length < 2) {
            paceMin.value = `0${paceMin.value}`;
        }
        paceSec.value = Math.floor(paceValue * 1000 % 60);
        if (paceSec.value.toString().length < 2) {
            paceSec.value = `0${paceSec.value}`;
        }
    }

    const removeMsg = () => {
        if (document.querySelector('.msg')) {
            document.querySelector('.msg').remove();
        }
    }

    calc.addEventListener('click', () => {
        removeMsg();
        const paceValue = +(paceMin.value * 60) + +(paceSec.value);
        const termValue = +termSec.value + +(termMin.value * 60) + +(termHr.value * 3600);
        const distValue = +dist.value;

        if ( isPaceValued() && isDistValued() && isTermValued()) {
            msg.textContent = 'Необходимо заполнить только 2 значения из 3-х';
            document.querySelector('.pace-calc').append(msg);
        } else if (isPaceValued() && isDistValued()) {
            calcTerm(paceValue, distValue);
        } else if (isPaceValued() && isTermValued()) {
            calcDist(paceValue, termValue);
        } else if (isDistValued() && isTermValued()) {
            calcPace(termValue, distValue);
        } else {
            msg.textContent = 'Необходимо заполнить 2 значения из 3-х';
            document.querySelector('.pace-calc').append(msg);
        }
    });

    event.addEventListener('input', (e) => {
        dist.value = event.value;
    });

    reset.addEventListener('click', () => {
        document.querySelector('form').reset();
        removeMsg();
    });
}

export default pace;